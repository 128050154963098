<template>
  <div>
    <div class="flex-container donate-container">
      <div class="flex-item">
        <div class="header">Donations</div>
        <div class="content">
          100% of ALL donations go to charitable services.
        </div>
        <div class="spacer"></div>

        <div class="extras">
          <apexchart
            :height="donate.where.chartHeight || 350"
            :options="{ ...chartOptions, ...chart2 }"
            :series="chart2.series"
          ></apexchart>
        </div>
      </div>

      <div class="flex-item">
        <div class="header" v-text="donate.where.title1"></div>
        <div class="content" v-text="donate.where.text1"></div>
        <div class="extras">
          <apexchart
            type="pie"
            :height="donate.where.chartHeight || 350"
            :options="{ ...chartOptions, ...chart1 }"
            :series="chart1.series"
          ></apexchart>
        </div>
      </div>
    </div>
    <div class="flex-container donate-container">
      <div class="flex-item">
        <div class="header">
          <span class="down-arrow-container">
            <span class="down-arrow"></span>
          </span>
          Donate to a Trip!
        </div>
        <div class="content">
          <template v-for="(trip, i) in trips">
            <router-link :key="trip.id" :to="`/trips/${trip.id}`">
              <span v-if="i != 0">|</span>
              <span class="trip-donate">{{ trip.displayName }}</span>
            </router-link>
          </template>
        </div>
      </div>

      <div class="flex-item mt-20">
        <div class="content" v-text="donate.who.subtitle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { firebase } from "../plugins";

export default {
  name: "Donate",
  data: () => ({
    trips: [],
    donate: { where: {}, who: {} },

    chart1: {
      series: [50, 45, 5],
      labels: ["Charitable Services", "Trip Costs", "Overhead"],
      plotOptions: { pie: { startAngle: 90, endAngle: -270 } }
    },
    chart2: {
      series: [100],
      labels: ["Charitable Services"]
    },

    chartOptions: {
      chart: { width: 380, type: "pie" },
      tooltip: { enabled: false },
      states: { hover: { filter: { type: "none" } } },
      legend: { show: false },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name, val.toFixed(1) + "%"];
        },
        offsetX: 100
      },
      colors: ["#0a414bcc", "#0a414bcc", "#0a414bcc"]
    }
  }),
  firestore: {
    trips: firebase.tripsRef.orderBy("order"),
    donate: firebase.pagesRef.doc("donate")
  }
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 160px;
  row-gap: 30px;
}

.donate-container {
  color: white;
}

.flex-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  min-width: 390px;
}

.header {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 24px;
}

.content {
  margin: auto;
  margin-bottom: 12px;
  text-align: center;
}

.spacer {
  margin: 8px auto;
}

.extras {
  margin: 12px auto;
}

.trip-donate {
  color: #0a414bcc;
  margin: 8px;
  font-size: 24px;
  font-weight: bold;
}
.trip-donate:hover {
  color: #29827f;
}


.down-arrow-container {
  position: relative;
  margin-right: 24px;
}
.down-arrow {
  position: absolute;
  top: 0px;
  width: 0;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
  animation: jumpInfinite 2.5s infinite;
}

.down-arrow:before {
  content: " ";
  position: absolute;
  top: 12px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 4px;
  transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
  0% {
    margin-top: -5px;
  }
  50% {
    margin-top: 15px;
  }
  100% {
    margin-top: -5px;
  }
}


@media (max-width: 800px) {
  
  .flex-container {
    margin: 0px 24px;
  }
  .flex-item {
    flex: 100%;
    max-width: 100%;
  }
}

.mt-20 {
  margin-top: 20px;
}
</style>
